@import "../variables/variables.scss";

.header {
  padding-top: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $color-bg;

  h1 {
    font-family: $font-serif;
    margin: 0;
    color: $color-text-dark;
  }

  h3 {
    font-family: $font-sans-serif;
    color: $color-text-dark;
    font-weight: 400;
  }

  h4 {
    color: $color-text-dark;
  }

  .arrow {
    svg {
      transform: rotate(95deg);
    }
  }

  .image-block {
    padding-top: 20vh;
    width: 100%;
    img {
      margin-inline: auto;
      overflow: hidden;
      object-fit: cover;
      object-position: center;
      width: 70%;
    }
  }
}
