@import "../variables/variables.scss";

.last-position {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .content {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    h2 {
      font-family: $font-serif;
      font-weight: 700;
    }
    p {
      font-family: $font-urbanist;
      text-align: left;
    }
    .buttons {
      display: flex;
      flex-direction: row;

      & > * {
        margin-right: 3vw;
        color: $color-global;
        font-family: $font-erotique;
        font-weight: 700;
        will-change: opacity, transform;
        &::before {
          content: "";
          height: 2px;
          width: 0%;
          background: currentColor;
          position: absolute;
          top: 93%;
          transition: width 0.3s;
        }

        &:hover {
          color: $color-effect-primary;
        }
        &:hover::before {
          content: "";
          height: 2px;
          width: 100%;
          background: $color-effect-primary;
          position: absolute;
          top: 93%;
          transition: width 0.3s;
          @media (max-width: 1023px) {
            width: 0%;
          }
        }
      }
    }
  }

  .jobs {
    display: flex;
    flex-direction: column;
    width: 30%;
    @media (max-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }
    .hidden {
      display: none;
    }
    .job {
      @media (max-width: 768px) {
        width: 40%;
      }
      .head {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        h3 {
          margin: 30px 30px 15px 0;
        }
      }
      .discover-more-wrapper {
        font-family: $font-urbanist;
        .date {
        }
        .location {
        }
        .job-title {
          font-weight: 500;
        }
      }
    }
  }

  .show-more > * {
    width: fit-content;
    margin: 30px 0;
    font-weight: 600;
    will-change: opacity, transform;
    &::before {
      content: "";
      height: 2px;
      width: 0%;
      background: currentColor;
      position: absolute;
      top: 93%;
      transition: width 0.3s;
    }

    &:hover {
      color: $color-effect-primary;
    }
    &:hover::before {
      content: "";
      height: 2px;
      width: 100%;
      background: $color-effect-primary;
      position: absolute;
      top: 93%;
      transition: width 0.3s;
      @media (max-width: 1023px) {
        width: 0%;
      }
    }
  }
}
