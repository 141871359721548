@import "../variables/variables.scss";

.clients {
  margin-top: 10vh;
  padding-bottom: 5vh;
  color: $color-global;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .col {
      width: 60%;
      .line {
        padding-bottom: 1em;
        border-bottom: 2px solid $color-global;
        .head {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .title {
            margin-block-end: 0.5em;
          }

          .indicator {
            font-family: $font-urbanist;
            font-weight: 300;
          }
        }
        .detail {
          margin-block-start: 0;
          font-family: $font-urbanist;
        }
      }
    }
    @media (max-width: 768px) {
      .col {
        width: 100%;
      }
    }
  }
}
