@import "../variables/variables.scss";

.project-item {
  color: inherit;
  .img-box {
    width: 90%;
    overflow: hidden;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  img {
    width: 100%;
    transition: transform 0.5s;
  }

  h3 {
    width: fit-content;
  }

  &:hover {
    h3 {
      color: $color-effect-primary;
    }

    img {
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}
