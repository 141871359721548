@import "../variables/variables.scss";

.project {
  .page-title {
    display: flex;
    justify-content: center;
    margin-bottom: 10vh;
    text-align: center;
  }

  .project-elements {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      // grid-column-gap: 15px;
      // grid-row-gap: 15px;
    }
  }
}
