@import "../variables/variables.scss";

.project-focus {
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1280px;
    padding: 5vh 0 0 0;
    margin-bottom: -10vh;

    @media (max-width: 1440px) {
      width: 1024px;
    }

    @media (max-width: 1024px) {
      width: 860px;
    }

    @media (max-width: 960px) {
      width: 720px;
    }

    @media (max-width: 767px) {
      width: 375px;
    }

    @media (max-width: 425px) {
      width: 275px;
    }

    @media (max-width: 321px) {
      width: 250px;
    }

    .year {
      font-family: $font-sans-serif;
      margin-top: 0;
    }

    .video {
      width: 100%;
    }

    .description {
      margin: 5vh 0;
      font-family: $font-sans-serif;

      h4 {
        text-align: center;
        margin-top: 0;
      }

      h5 {
        font-style: italic;
        text-align: center;
        margin-top: -1.33em;
      }

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          color: $color-effect-primary;
        }
      }
    }

    .images {
      display: flex;
      flex-direction: column;
      width: 100%;
      .row {
        display: flex;
        width: 100%;
      }
      .col {
        flex: 1;
        padding: 5px;
      }
      .col img,
      .col-full img {
        width: 100%;
        height: auto;
      }

      .col-full {
        flex: 1;
        padding: 5px;
        width: 100%;
      }
    }

    .close-text {
      padding-top: 5vh;
      will-change: opacity, transform;
      text-align: center;

      &::before {
        content: "";
        height: 2px;
        width: 0%;
        background: currentColor;
        position: absolute;
        top: 93%;
        transition: width 0.3s;
      }

      &:hover {
        color: $color-effect-primary;
      }
      &:hover::before {
        content: "";
        height: 2px;
        width: 100%;
        background: $color-effect-primary;
        position: absolute;
        top: 93%;
        transition: width 0.3s;
        @media (max-width: 1023px) {
          width: 0%;
        }
      }
    }
  }
}
