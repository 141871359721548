@import "../variables/variables.scss";

.work {
  width: 100vw;
  .content {
    border-bottom: 1px solid $color-border;
    position: relative;

    .row {
      // z-index: 1;
      color: $color-global;
      display: grid;
      position: relative;
      cursor: pointer;
      grid-template-rows: $img-size;
      grid-template-columns: auto 1fr;
      grid-column-gap: 5vw;
      align-items: center;
      padding: $padding-row $padding-sides;
      border-top: 1px solid $color-border;
      transition: background-color 0.3s ease-out, border-color 0.3s ease-out;

      &:hover {
        background-color: #ced4da;
      }

      .cell {
        position: relative;
      }

      .cell-text {
        .oh {
          position: relative;
          // overflow: hidden;
        }

        .oh__inner {
          will-change: transform;
          display: inline-block;
        }

        .cell-title {
          font-family: $font-urbanist;
          font-weight: 500;
          margin: 0;
          position: relative;
          white-space: nowrap;
          display: grid;
          grid-template-rows: 100% 100%;
        }

        .title-switch {
          font-family: $font-erotique;
          font-weight: 700;
          // color: $color-secondary;
        }
      }

      .cell-images {
        display: grid;
        align-content: center;
        grid-auto-columns: auto;
        grid-auto-flow: column;
        grid-gap: $image-gap;
        justify-content: end;
        margin-left: auto;

        .img {
          width: $img-size;
          display: grid;
          position: relative;
          grid-template-columns: 100%;
          grid-template-rows: auto auto;
          will-change: transform, opacity;
          opacity: 0;

          .img-inner {
            background-size: cover;
            background-position: 50% 50%;
            aspect-ratio: 1;
            width: 100%;
            border-radius: calc($image-gap / 3);
          }
        }
      }
    }
  }

  .overlay {
    // position: fixed;
    z-index: 2;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    fill: $color-global;
  }

  .block {
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 100vw;
    z-index: 3;
  }

  .block-open {
    height: 100vh;
  }
  .block-color {
    background-color: lightblue;
  }
}

/*
   

*/
