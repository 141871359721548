@import "../variables/variables.scss";

.footer {
  margin-top: 15vh;
  background-color: $color-global;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    color: $color-light;
    margin-block-end: 0.4em;
  }

  .mail {
    color: $color-light;
    will-change: opacity, transform;
    &::before {
      content: "";
      height: 2px;
      width: 0%;
      background: currentColor;
      position: absolute;
      top: 93%;
      transition: width 0.3s;
    }

    &:hover {
      color: $color-effect-primary;
    }
    &:hover::before {
      content: "";
      height: 2px;
      width: 100%;
      background: $color-effect-primary;
      position: absolute;
      top: 93%;
      transition: width 0.3s;
      @media (max-width: 1023px) {
        width: 0%;
      }
    }
  }

  .links {
    margin: 100px 0 50px 0;
    display: flex;
    flex-direction: row;
    @media (max-width: 1440px) {
      margin: 50px 0 25px 0;
    }
    @media (max-width: 640px) {
      margin: 25px 0 12.5px 0;
    }
    & > * {
      color: $color-light;
      font-family: $font-sans-serif;
      padding: 0 2vw;
      &:hover {
        color: $color-effect-primary;
      }
    }
  }

  .credits {
    text-align: center;
    font-family: $font-sans-serif;
    color: $color-light;
    margin-bottom: 2.5vh;
  }
}
