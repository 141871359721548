@import "./variables/variables.scss";

body {
  margin: 0;
  background-color: $color-bg;
  font-family: $font-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text-dark;
  overflow-x: hidden;
}

.window {
  margin: auto;
  // max-width: 2056px;
  height: 100%;
}

.cursor {
  display: none;

  @media (any-pointer: fine) {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    z-index: 10000;
  }
}

.cursor__inner {
  @media (any-pointer: fine) {
    fill: none;
    stroke: $cursor-stroke;
    stroke-width: 1.3px;
  }
}

.disable-scroll {
  overflow: hidden;
}

a {
  text-decoration: none;
}

.spacing-block {
  padding: 0 10vw;
  @media (max-width: 768px) {
    padding: 0 12vw;
  }
}

.spacing-small {
  height: 25vh;
  background-color: $color-global;
}
.spacing {
  height: 100vh;
  background-color: $color-bg;
}

.test-bottom {
  padding-bottom: 80vh;
}
