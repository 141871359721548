// COLORS
$color-dark: #111111; //unused
$color-light: #e5e5e5;
$color-global: #361fdb;
$color-secondary: #cc1111;

// BACKGROUND
$color-bg: $color-light;

// TEXT
$color-text-dark: $color-global;
$color-text-light: $color-light;

// LINK
$color-effect-primary: $color-secondary;
$cursor-stroke: $color-secondary;

// BUTTON MENU
$color-button-menu: $color-global;
$color-button-menu-hover: $color-global;
