.cv {
  padding-top: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50vw;
  }

  @media (max-width: 1280px) {
    img {
      width: 70vw;
    }
  }

  @media (max-width: 768px) {
    img {
      width: 90vw;
    }
  }
}
