@import "../variables/variables.scss";

main {
  position: fixed;
  top: 0;
  height: 0;
  width: 100vw;
  z-index: 2;

  .unbutton {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }

  .frame {
    background-color: $color-bg;
    // width: calc(100vw - 120px);
    padding: 3vh 60px;
    height: 7vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 4;

    @media (max-width: 768px) {
      padding: 3vh 40px 0;
    }

    @media (max-width: 639px) {
      padding: 3vh 20px 0;
    }

    .language__switcher {
      button {
        font-family: $font-sans-serif;
        font-weight: 400;
        color: $color-text-dark;
        border: none;
        background-color: transparent;
        padding: 0;
        &:first-child::after {
          content: "/";
          padding: 0 5px;
          font-weight: 400;
        }
      }
    }

    .logo {
      svg {
        height: 50px;
        width: 50px;
        fill: $color-global;
      }
    }

    .title {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > * {
        margin: 0;
      }

      h3 {
        color: $color-text-dark;
        margin: 0 0 5px 0;
        font-family: $font-serif;
        font-weight: 700;

        @media (max-width: 768px) {
          margin: 0;
        }
      }

      h4 {
        color: $color-text-dark;
        font-family: $font-sans-serif;
        font-weight: 400;
      }
    }

    .button__menu {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 1px solid $color-button-menu;
      fill: $color-button-menu;

      @media (max-width: 768px) {
        height: 48px;
        width: 48px;
      }

      @media (max-width: 639px) {
        height: 36px;
        width: 36px;
      }

      svg {
        transition: transform 0.5s ease;
        height: 12px;
        width: 19px;

        @media (max-width: 768px) {
          height: 10px;
          width: 16px;
        }

        @media (max-width: 639px) {
          height: 9px;
          width: 14px;
        }
      }

      &:focus-visible,
      &:hover {
        fill: $color-button-menu-hover;
        border-color: $color-button-menu-hover;
      }

      &:hover svg {
        transform: scale(1.1);
      }
    }

    .button__close {
      top: 0;
      right: 0;
      stroke: $color-text-light;
      fill: none;
      display: none;

      &:focus-visible,
      &:hover {
        stroke: $color-effect-primary;
      }
    }
  }

  .frame--menu-open {
    background-color: $color-global;

    .language__switcher {
      z-index: 4;
      button {
        color: $color-text-light;
      }
    }

    .title {
      z-index: 4;
      h3 {
        color: $color-text-light;
      }
      h4 {
        color: $color-text-light;
      }
    }

    .button__close {
      z-index: 4;
      display: block;
    }

    .button__menu {
      display: none;
    }
  }

  .menu__wrap {
    grid-area: 1 / 1 / 2 / 2;
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 100vh;
    position: absolute;
    background: $color-global;
    pointer-events: none;
    opacity: 0;
    overflow: hidden;
    top: 0;
    left: 0;

    .tiles {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 150vh;
      display: flex;
      opacity: 0.5;
      flex-direction: column;
      justify-content: center;
      transform: translate3d(-50%, -50%, 0) rotate(22.5deg);

      .tiles__line {
        display: flex;
        transform: translateX(25%);
        animation: runner 10s linear infinite;

        .tiles__line-img {
          flex: none;
          width: 30vh;
          height: 30vh;
          margin: 3vw;
          background-size: cover;
          background-position: 50% 50%;
          border-radius: 50%;
        }

        .tiles__line-img--large {
          width: 100vh;
          border-radius: 20vh;
        }

        @keyframes runner {
          to {
            transform: translateX(-25%);
          }
        }
      }

      .tiles__line:nth-child(2) {
        animation-duration: 16s;
      }

      .tiles__line:nth-child(3) {
        animation-duration: 22s;
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      height: 100%;

      .menu__item {
        margin: 2vh 0;
        color: $color-text-light;
        cursor: pointer;
        line-height: 1;
        font-weight: 300;
        text-align: center;
        position: relative;
        will-change: opacity, transform;
        &::before {
          content: "";
          height: 3px;
          width: 0%;
          background: currentColor;
          position: absolute;
          top: 93%;
          transition: width 0.3s;
        }

        &:hover {
          color: $color-effect-primary;
        }
        &:hover::before {
          content: "";
          height: 3px;
          width: 100%;
          background: $color-effect-primary;
          position: absolute;
          top: 93%;
          transition: width 0.3s;
          @media (max-width: 1023px) {
            width: 0%;
          }
        }
      }
    }
  }

  .menu__wrap.menu-wrap--open {
    pointer-events: auto;
    opacity: 1;
    z-index: 3;
  }

  .overlay {
    position: absolute;
    z-index: 2;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    fill: $color-global;
  }
}

.main-open {
  height: 100vh;
}
